// @import "../../../../node_modules/bootstrap/scss/bootstrap-reboot";
@import './../../../styles/var';
@import './../../../styles/mixins';

.notification_item {
  display: flex;
  align-items: flex-start;
  background: transparent;
  padding: 0;
  margin-bottom: 12px;
  font-size: 12px;
  line-height: 1;

  &_content {
    margin: 0 9px;
    flex-grow: 1;
    // display: flex;

    &_time {
      margin-left: 16px;
    }
  }

  &_text {
    // width: 157px;
    white-space: normal;
    text-overflow: ellipsis;
    overflow: hidden;
    display: flex;
    flex: 1
  }

  &_image {
    @include imageSize(cover, center);
    @include size(32px);
  }

  &_user_name {
    color: $text-color;
    font-weight: $medium;
    margin-bottom: 5px;

    &:hover {
      color: $text-color;
    }
  }

  &_info {
    color: $gray-color  !important;
    display: flex;
    margin: 0;
  }
}

@import '../../styles/var';
@import '../../styles/mixins';
// @import './../../styles/icoMoon';
.some_parent{
  height: 447px;
}
.start_language{
  // position: absolute;
  // right: 60px;
  // top: 10px;
  display: flex;
  align-items: center;
  color: rgb(54, 54, 54);
}
.world{
  width: 25px;
  height: fit-content;
  margin-right: -7px;
  z-index: 0;
}
.menu_item, .top_link_active{
  white-space: nowrap;
  padding: 0;
  margin-right: 65px;
  color: rgba(54, 54, 54, 1);
  border: 1px solid $white;
  @include media-breakpoint-down-lg {
    margin-right: 30px;
  }
  @include media-breakpoint-down-sm {
    margin-right: 15px;
    font-size: 13px;
    border: none
  }
}
.main_header{
  @include media-breakpoint-down-sm {
    display: none;
  }
}
// .my_profile_img{
//   margin-left: 35px;
// }
.menu_item{
  color: #977575 !important;
  font-size: 16px;
  height: fit-content;
  &:hover{
    border-bottom: 1px solid #977575 !important;
  }
}
.top_link_active{
  border-bottom: 1px solid #977575 !important;
}
.active{
    color: $white !important;
    font-size: 24px;
    line-height: 1;
    font-weight: $bold;
    margin-right: 47px;
}
.sub_header {
  background-image: linear-gradient(90deg, $primary-color 0%, #FBB0B5 100%);
  padding: 14px 10px;
  white-space: nowrap;
  // overflow: hidden;
  min-height: 70px;
  &_item {
    color: $white !important;
    font-size: 24px;
    line-height: 1;
    font-weight: $bold;
    margin-right: 47px;
    height: 25px;
    white-space: break-spaces;
    &:hover{
      border-bottom: 1px solid $white;
      @include media-breakpoint-down-sm {
        border: none;
      }
    }
    @include media-breakpoint-down-lg {
      margin-right: 25px;
    }
    @include media-breakpoint-down-md {
      margin-right: 20px;
      font-size: $fz-16;
    }
    @include media-breakpoint-down-sm {
      padding: 14px 20px !important;
       font-size:24px;
       border: none;
       display: flex;
       height: fit-content;
      &.active {
        background: #FBB0B5;
        width: 100%;
      }
    }
  }
  
  @include media-breakpoint-down-sm {
    .container {
      padding: 0 15px;
    }
  }
}

.header_notification_btn {
  margin-left: 45px;
  .dropdown-toggle {
    padding: 0 !important;
    &::after {
      font-family: 'icomoon';
      content: '\e914';
      border: none;
      color: $white;
      font-size: 34px;
      @include media-breakpoint-down-md {
        font-size: 28px;
      }
      @include media-breakpoint-down-sm {
        font-size: 23px !important;
      }
    }
  }
 
  &::before {
    // @include border-radius(50%);
    @include size(14px);
    content: attr(data-count);
    position: absolute;
    right: -3px;
    top: 3px;
    background: $white;
    color: $primary-color;
    border: 1px solid $title-color;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%
  }
 
  @include media-breakpoint-down-lg {
    margin-left: 20px;
  }
}

// .like_icon {
//   color: $white !important;
//   font-size: 34px;
//   padding: 0;
//   margin: 0 40px 0 35px;
//   @include media-breakpoint-down-lg {
//     margin: 0 20px 0 15px;
//   }
//   @include media-breakpoint-down-md {
//     font-size: 24px;
//   }
// }

.header_profile {
  @include size(58px);
  @include imageSize(cover, center);
  border: 2px solid $white;
  border-radius: 50%;
  @include media-breakpoint-down-md {
    @include size(45px);
  }
  @include media-breakpoint-down-xs {
    @include size(37px);
  }
}

.search {
  position: relative;
  margin-right: 45px;
  width: 50px;
  display: flex;
  flex: 1;
  transition: all 0.25s ease-in;
  &_input {
    border: none !important;
    border-radius: 0 !important;
    border-bottom: 2px solid $white !important;
    background: transparent !important;
    box-shadow: none;
    color: white !important;
    // padding: 5px 10px 5px 30px;
    padding: 0.375rem 1.75rem !important;
    &:focus {
      border-color: $white;
      box-shadow: none !important;
    }
  }
  &_btn {
    left: 0;
    top: 0px;
    color: $white;
    font-size: $fz-18;
    position: absolute;
    box-shadow: none;
    padding: 0;
    width: 50px;
    cursor: pointer;
    // "position-absolute p-0 shadow-none search-btn"
    &:hover,
    &:focus {
      color: inherit;
    }
    @include media-breakpoint-down-sm {
      left: 15px;
    }
  }
  @include media-breakpoint-down-md {
    display: none;
  }
  @include media-breakpoint-down-sm {
    display: flex;
    order: 1;
    padding: 0 15px;
    width: 100%;
    margin-bottom: $fz-18;
    flex-grow: 0 !important;
    margin-right: 0;
  }
}
.active_search{
  width: 230px;
  transition: all 0.25s ease-in;
  color: white;
}
.active{
  border-bottom: 1px solid $white;
}

.link_active{
  color: white;
}
.active, .top_link_active, .link_active{
  @include media-breakpoint-down-sm {
    border: none;
  }
}
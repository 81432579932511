@import './../../styles/var';
@import './../../styles/mixins';

.post-main-avatar {
    padding: 2px
}

.border_style {
    margin-top: 30px;
}

.comments_text_item {
    margin: 2px;
}

.report_input {
    width: 100%;
    border: none;
    outline: none !important;
}

.with_ellipses {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    // max-height: 42px
}

.post_frist_image {
    @include imageSize(cover, top);
    // border-radius: 0px;
}

.next_date {
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    /* padding: 3px 10px; */
    /* border-radius: 15px; */
    margin-right: 3px;
    font-size: 14px;
    /* background: #d6efdb; */
    /* color: #1c623d; */
    cursor: pointer;
    font-weight: 900;
}

.service_name {
    font-size: 14px;
}

.service_price {
    white-space: nowrap;
    font-weight: $bold;
    /* color: $primary-color;*/
    font-size: 14px;
}

.free_day {
    display: -moz-box;
    -moz-box-align: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    position: absolute;
    flex-direction: column;
    display: flex;
    padding: 5px;
    right: 0;
    top: 0;
    width: 120px !important;
    font-size: 10px;
    border: 1px solid #fff;
    border-radius: 10px;
    margin: 0px 10px;
    /* box-shadow: 1px 1px 1px #949494; */
    .label {
        /* margin-right: 15px;
        font-size: 14px; */
    }
}
.see_more{
    margin: 0px 5px;
}
.masterName {
    width: 70% !important;
}

.next_date_post {
    display: -moz-box;
    display: flex;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    /* padding: 6px; */
    font-weight: 600;
    font-size: 16px;
    border-radius: 15px;
    margin-right: 3px;
    font-size: 14px;
    /* background: #d6efdb; */
    /* color: #1c623d; */
    cursor: pointer;

    @include media-breakpoint-down-sm {
        font-size: 13px;
    }
}
.text_name{
    // font-size: $fz-20;
    font-weight: 500;
    margin-bottom: 0px !important;
    white-space: nowrap;
    float: left;
}
.service_name_post {
    word-break: break-word;
    width: 50%;
    @include media-breakpoint-down-sm {
        width: fit-content;
    }
    /*font-size: 18px;*/
}

.service_price_post {
    white-space: nowrap;
    font-weight: $bold;
    /* color: $primary-color; */
    font-size: 18px;
    margin-top: -8px;
}

.free_day_post {
    display: flex;
    align-items: center;
    // justify-content: space-between;
    width: fit-content;

    .label {
        /* margin-right: 15px;
        font-size: 18px;*/
    }
}

.comment_image {
    @include size(22px);
    margin-right: 9px;
    border-radius: 50%;
}

.hash_tag_item {
    color: #0d6efd !important;
    text-decoration: underline !important;
    cursor: pointer;
    margin-right: 10px;
}

.hash_tag_list {
    margin-bottom: 22px;
    display: flex;
}

.title_see_moree {
    cursor: pointer;
}
.zip_code{
    color: $white;
    font-size: $fz-14;
    // margin-top: 15px;
}
.name {
    font-weight: 500;
    font-size: $fz-18;
    line-height: 21px;
    color: $text-color;
    word-break: break-word;
    width: 50%;
    @include media-breakpoint-down-sm {
        width: 100%;
    }
}

.descrition {
    font-size: $fz-10;
    line-height: 12px;
    color: #9D9D9D;
}

.review_item {
    margin-bottom: 0px;
}

.comments_cont {
    margin: 0px 0px;
    position: relative;
    min-height: 300px;
    .avatar_container {
        /* background: #f8f8f8; */
        padding: 10px;
        /* border-top-left-radius: 10px; */
        /* border-top-right-radius: 10px; */
        Position: absolute;
        z-index: 999;
        color: white;
        text-shadow: 1px 1px black;
        width: 100%;
    }

    .title_see_moree {
        @include size(126px, 22px);
        background: #F3F3F3;
        border-radius: 40px;
        margin-top: 4px;
        font-weight: $regular;
        font-size: $fz-12;
        line-height: 14px;
        color: #969696;
        cursor: pointer;
    }

    .comments_images {
        position: relative;
        // display: flex;
        // flex-wrap: wrap;
        // width: 80% !important;
        max-width: 1320px;
        width: 100%;
        
        // height: 800px;
        .ReactGridGallery_tile-viewport {
            border-radius: 10px !important;

            img {
                width: 100% !important;
                height: auto !important;
                margin-left: 0 !important;

            }
        }

        .ReactGridGallery_tile {
            margin-bottom: 10px !important;
        }

        .more_images {
            // "d-flex p-absolute justify-content-center align-items-center" 
            display: flex;
            position: absolute;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            color: $white;
            font-weight: $bold;
            margin: 10px;
            bottom: 0;
            right: 0;
        }

        .img_count {
            position: absolute;
            z-index: 10;
            width: 45px;
            height: 20px;
            background: #fff;
            bottom: 10px;
            right: 5px;
            border-radius: 120px;
            opacity: .85;
            display: -moz-box;
            display: flex;
            -moz-box-pack: center;
            justify-content: center;
            -moz-box-align: center;
            align-items: center;
            color: #a4a4a4;
            font-size: 10px;
        }
    }

    @include media-breakpoint-down-sm {
        flex-direction: column;
        margin-top: 12px;

        .comments_images {
            display: none !important;
        }

        .mobile_work_slider {
            width: 100%;
            margin: auto;
            // height: 255px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            position: relative;
            background: #d2d2d2;
            // min-height: 400px;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;

            div {
                right: 10px;
                bottom: 17px;
                position: absolute;
                background: rgba(95, 95, 95, .5803921569);
                opacity: .85;
                border-radius: 120px;
                width: 40px;
                height: 23px;
                font-size: 12px;
                line-height: 14px;
                color: #fff;
            }

            .comments_slide_images {
                width: 100%;
                height: 100%;
                border-radius: 0;
                margin: auto;
                // height: 400px;
            }
        }

    }
}

.comments_images {

    .gallery_image_link,
    .gallery_video_link {
        cursor: pointer;
        min-height: 300px;
        // &:first-child {
        .gallery_image {
            width: 100%;
            // @include size(100%, 800px);
            // margin-bottom: 23px;
            cursor: pointer;
            object-fit: cover;
            object-position: center;

            img {
                height: auto !important;
            }
        }

        @include media-breakpoint-down-md {
            .gallery_image {
                // @include size(100%, 400px);
            }
        }

        .gallery_video {
            // @include size(100%, 800px);
            // margin-bottom: 23px;
            cursor: pointer;
            object-fit: cover;
            object-position: center;
        }

        // }

        // &:nth-child(2) {

        .gallery_image,
        .gallery_video {
            margin-right: 19px;
        }
    }

    .gallery_image,
    .gallery_video {
        // @include size(177px, 128px);
        @include imageSize(cover, top);
        border-radius: 10px;
    }

    // }

}

.prf_image {
    @include size(54px);
    @include imageSize(cover, center);
    border-radius: 50%;
    margin-right: 15px;
    border: 2px solid white;

    @include media-breakpoint-down-sm {
        @include size(55px);
        margin-right: 10px;
        margin-left: 10px;
        margin-top: 10px;
    }
}

.child_comment_text_bottom_data {
    margin-bottom: 20px;

    span {
        font-size: 12px !important;
        line-height: 14px !important;
        color: #D2D2D2 !important;
    }
}

.comment_data {
    margin-left: 0.3rem;
    display: flex;
    flex-direction: column;
    word-wrap: break-word;

    @include media-breakpoint-down-sm {
        margin: 0px !important;
        /* border-radius: 5px;
        padding: 0px;
        background-color: white;
        box-shadow: 3px 2px 5px #ffe8e8;*/
    }

    flex: 1;

    img {
        @include size(54px);
        @include imageSize(cover, center);
        border-radius: 50%;
        margin-right: 15px;
        border: 2px solid white;
        // margin-bottom: 8px;

        @include media-breakpoint-down-sm {
            @include size(45px);
            margin-right: 10px;
        }
    }

    .descrition {
        font-size: $fz-10;
        line-height: 12px;
        color: #9D9D9D;

        @include media-breakpoint-down-sm {
            font-size: 8px;
            line-height: 9px;
            margin-top: 2px;
        }
    }

    .name,
    .review-name {
        font-weight: $medium;
        font-size: $fz-18;
        line-height: 21px;

        @include media-breakpoint-down-sm {
            font-size: $fz-14;
            line-height: 16px;
        }
    }

    .review_name {
        margin-right: 20px;
        font-weight: $medium;
    }

    .review_name_rating {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down-sm {
            flex: 1;
        }
    }

    .response {
        display: flex;
        flex-direction: column;
        margin-left: 64px;

        @include media-breakpoint-down-sm {
            margin-left: 20px;

            .text {
                margin-top: 0;
            }
        }

        .title {
            font-weight: $medium;
            font-size: $fz-18;
            line-height: 21px;
            letter-spacing: 0.25px;
            color: $gray-color;

            @include media-breakpoint-down-sm {
                font-size: $fz-12;
                line-height: 14px;
                margin-bottom: 2px;
            }
        }
    }

    .review_date {
        font-weight: $regular;
        font-size: $fz-12;
        line-height: 14px;
        color: #D2D2D2;
        margin-top: 0px;
    }

    .review_item {
        .name {
            margin-top: 0px;
        }
    }

    .text {
        margin-bottom: 2px;
        font-size: $fz-18;

        @include media-breakpoint-down-sm {
            font-size: $fz-12;
            margin-top: -15px;
            padding: 0 5px;
            font-style: italic;
            margin-bottom: 15px;
        }
    }
    

    .comment-name {
        flex: 1;
        position: relative;
    }

    .see_all_comments {
        margin-top: 23px;
        margin-bottom: 0px;
        font-size: 12px;
        line-height: 14px;
        text-decoration-line: underline;
        color: #747474;
        cursor: pointer;

        @include media-breakpoint-down-sm {
            margin-top: 0;
        }
    }

    .comment_image {
        @include size(22px);
        border-radius: 50%;
    }

    .options-cont {
        @include size(194px, 181px);
        top: 612px;
        background: #FFFFFF;
        opacity: 0.98;
        box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        position: absolute;
        top: 0;
        right: 0;
    }
}

.MuiButton-label {
    font-size: 25px;
    color: #C4C4C4;
}

.MuiButtonBase-root {
    outline: none !important;
    border: none !important;
    height: 25px !important;

    @include media-breakpoint-down-sm {
        height: 5px !important;
    }
}

.MuiPaper-elevation8 {
    background: #FFFFFF !important;
    opacity: 0.98 !important;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25) !important;
    border-radius: 10px !important;
}

.MuiMenuItem-root {
    font-size: 14px !important;
    line-height: 16px !important;
    color: $text-color !important;
    min-height: 48px !important;
}

.comments_info {
    display: flex;
    cursor: pointer;
    background: #5f5f5f94;
    padding: 5px 5px 4px 5px;
    margin: 0 4px;
    border-radius: 20px;

    &_count {
        font-weight: $regular;
        font-size: $fz-12;
        line-height: 14px;
        color: white;
        margin-right: 5px;
    }

    &_right {
        margin-left: 2px;
        margin-top:-4px;
    }
}

.comment_item {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 64px;

    @include media-breakpoint-down-sm {
        margin-top: 38px;
    }

    img {
        @include size(26px);
        margin-right: 10px;
    }

    .name {
        font-size: $fz-14;
        line-height: 16px;

        @include media-breakpoint-down-sm {
            line-height: 16px;
        }
    }
   
    .text {
        font-size: $fz-18;
        line-height: 130%;
        margin-top: 5px;
        margin-bottom: 12px;
        margin-left: 36px;

        @include media-breakpoint-down-sm {
            font-size: 12px;
        }
    }
   
    .text_bottom_data {

        // @include size(284px,14);
        // margin-bottom: 17px;
        span:first-child {
            font-size: $fz-12;
            line-height: 14px;
            color: #D2D2D2;
        }

        span:last-child {
            line-height: 14px;
            color: #969696;
            font-size: $fz-12;
            cursor: pointer;
        }

        .answer {
            display: flex;
            justify-content: space-between;
            width: 284px;
            margin-left: 23px;
            margin-bottom: 20px;
        }
    }

    .comment_data {
        @include media-breakpoint-down-sm {
            margin-left: 14px !important;
        }

        .child_comment_data {
            display: flex;
            flex-direction: column;
            margin-left: 15px !important;

            @include media-breakpoint-down-sm {
                margin-left: 12px !important;
            }
        }
    }
}